const careers = {
  ENGINEERING: {
    fullStackEngineer: {
      role: 'Full-Stack Engineer - JavaScript',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Full-Stack Engineer to join our professional, internationally distributed engineering and product team. This is a remote full-time position.`,
      idealCandidate: [
        '3+ years of experience building JavaScript web applications',
        '3+ years of Backend/RESTful API development experience',
        '2+ years experience with Relational Databases',
        '1+ years of experience with React/Angular/Next',
        'Clean Code, SOLID principles and design patterns knowledge',
        'Event sourcing knowledge, message queues and caches',
        'Strong knowledge of basic software engineering concepts (data structures, algorithms, OOP)',
        'Deep knowledge of JavaScript / ES6 practices and commonly used modules based on extensive work experience',
        'Experience or good Typescript knowledge',
        'Experience writing Unit tests',
        'Strong working knowledge of Git',
        'Ability to read and understand English software requirements, strong written English, ability to conduct interviews in English',
      ],
      niceToHave: [
        'Microservice expertise',
        'AWS/Google Cloud or any equivalent cloud experience',
        'Continuous Integration experience with CircleCI or similar',
      ],
      typicalDay: [
        'Work with our design and development teams to implement features',
        'Be responsible for overall backend/api and frontend code quality',
        'Uphold our high engineering standards and bring consistency to our product development',

        'Work with our team to create both technically viable and beautiful user experiences',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Contract position',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    backendEngineerJavascript: {
      role: 'Backend Engineer - JavaScript',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Mid or Senior Backend Engineer to join our engineering team. This is a remote full-time contract position.`,
      idealCandidate: [
        '3+ years of Backend/RESTful API development experience',
        '3+ years experience with Node.js',
        '2+ years experience with Typescript',
        '2+ years experience with Relational (MySQL, Postgres, etc) and NoSQL (MongoDB, DynamoDB, etc) databases',
        'Strong knowledge of basic software engineering concepts (data structures, algorithms, OOP)',
        'Microservice expertise',
        'Experience writing Unit/Integration tests for backend applications (Mocha, Chai, Sinon, etc)',
        'Familiarity with the modern JS toolchain - npm, yarn, webpack, etc',
        'Strong working knowledge of Git',
        'Clean Code, SOLID principles, DRY, KISS and Design Patterns knowledge',
        'Ability to read and understand English software requirements, strong written English, ability to conduct an interview in English',
      ],
      niceToHave: [
        'Event Sourcing knowledge/experience',
        'AWS + GCP cloud expertise',
        'Continuous Integration experience with Jenkins/CircleCI or similar',
        'Frontend experience in React or Angular',
        'Experience with Nest (https://nestjs.com/)',
        'Bachelor’s Degree in Computer Science, Applied Mathematics, Electrical Engineering, etc or equivalent experience',
      ],
      typicalDay: [
        'Work with our design and development teams to implement features',
        'Uphold our high engineering standards and bring consistency to our product development',
        'Work with our team to create both technically viable and beautiful user experiences',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 and leading tech companies',
        'Competitive salary',
        'Contract position',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    frontendEngineerReact: {
      role: 'Frontend Engineer - React.JS',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Mid or Senior Frontend Engineer to join our engineering and product team. This is a remote full-time contract position.`,
      idealCandidate: [
        '3+ years of experience building JavaScript web applications',
        '2+ years experience with ReactJS and TypeScript',
        'Expert understanding of HTML5 (specifically semantic elements), CSS/SASS, UI frameworks (e.g. Bootstrap, Material UI, Tailwind)',
        'Deep knowledge of JavaScript / ES6 practices and commonly used modules based on extensive work experience, with a focus on ReactJS',
        'Experience writing Unit tests for ReactJS applications',
        'Familiarity with web trends and best practices for UI/UX design',
        'An appreciation for clean code and design principles, including Accessibility guidelines',
        'Strong working knowledge of Git',
        'Ability to read and understand software requirements, write technical documentation, and conduct a technical interview in English',
      ],
      niceToHave: [
        'An eye for building pixel perfect, beautiful, and intuitive apps',
        'Experience with GraphQL (https://graphql.org/)',
      ],
      typicalDay: [
        'Work with our design and development teams to implement frontend code',
        'Be responsible for overall code quality',
        'Work with our team to create both technically viable and beautiful user experiences',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Competitive salary',
        'Contract Job',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `Along with your CV, please include links to relevant code samples (Typescript, HTML, CSS, etc) and/or links to recent projects. We kindly ask agencies to not bother applying.`,
    },
    qaEngineerJava: {
      role: 'QA Automation - Java',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented QA Engineer to join our engineering team. This is a remote full-time position. You will plan, coordinate, and manage the successful execution of software quality assurance. You should be able to establish quality assurance processes and be responsible for their execution.`,
      idealCandidate: [
        '3+ years of Automation Quality Assurance Experience',
        '3+ years working directly on Software Development Projects',
        '2+ years working experience with Selenium Webdriver',
        'Ability to write clean structured code',
        'Ability to take ownership of the successful launch of quality software projects',
        'Advanced experience creating & managing Software test plans',
        'Outstanding Attention to Detail',
        'Agile/Scrum Experience',
        'Automation Test Experience',
        'Strong Technical Knowledge of the Software Product Development Lifecycle',
        'Ability to communicate effectively with stakeholders and product team',
        'Strong Verbal English Skills',
      ],
      niceToHave: [
        'Experience or equivalent automated test software',
        'Bachelor’s Degree in Engineering or related study',
        'Masters Degree in Engineering or related study',
        'Knowledge of CI/CD and popular solutions in the market',
      ],
      typicalDay: null,
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Competitive salary',
        'Full-time remote position',
        'Contract position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    qaEngineerJavascript: {
      role: 'QA Automation - JavaScript',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented QA Engineer to join our engineering team. This is a remote full-time position. You will plan, coordinate, and manage the successful execution of software quality assurance. You should be able to establish quality assurance processes and be responsible for their execution.`,
      idealCandidate: [
        '3+ years of Automation Quality Assurance Experience',
        '3+ years working directly on Software Development Projects',
        '1+ years working experience with Cypress/Webdriver.io/Playwright or equivalent Javascript testing framework',
        'Ability to write clean structured code',
        'Ability to take ownership of the successful launch of quality software projects',
        'Advanced experience creating & managing Software test plans',
        'Outstanding Attention to Detail',
        'Agile/Scrum Experience',
        'Automation Test Experience',
        'Strong Technical Knowledge of the Software Product Development Lifecycle',
        'Ability to communicate effectively with stakeholders and product team',
        'Strong Verbal English Skills',
      ],
      niceToHave: [
        'Bachelor’s Degree in Engineering or related study',
        'Experience or equivalent automated test software',
        'Masters Degree in Engineering or related study',
        'Knowledge of CI/CD and popular solutions in the market',
      ],
      typicalDay: null,
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Competitive salary',
        'Full-time remote position',
        'Contract position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    devOpsEngineer: {
      role: 'DevOps Engineer',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented DevOps Engineer to join our engineering team. This is a remote full-time contract position.`,
      idealCandidate: [
        'Strong working knowledge of Docker ecosystem',
        '1+ years experience of deploying and managing Kubernetes clusters',
        'Experience with Nginx and other proxies and load balancers',
        'Continuous Integration experience with Jenkins/Bamboo or similar',

      ],
      niceToHave: [
        'Experience with Terraform and other IaC tooling',
        'Experience with Google Cloud Platform',
        'AWS cloud expertise',
        'Prior experience in the operation of event-sourced, microservice based systems',
        'Bachelor’s Degree in Computer Science, Applied Mathematics, Electrical Engineering, etc or equivalent experience',
        'Strong Verbal English Skills',
      ],
      typicalDay: null,
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Contract position',
        'Competitive salary',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    dotNetEngineer: {
      role: '.Net Engineer',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Mid or Senior .Net Engineer to join our engineering team. This is a remote full-time contract position.`,
      idealCandidate: [
        '3+ years of experience working with .Net on a production product',
        '3+ years experience with Relational Databases',
        'Clean Code, SOLID principles and design patterns knowledge',
        'Strong knowledge of .NET/Microsoft technologies (Asp.Net MVC/WebApi and/or WPF/UWP, Entity Framework, Windows Services etc.)',
        'Strong knowledge of basic software engineering concepts (data structures, algorithms, OOP)',
        'Experience writing Unit tests',
        'Strong working knowledge of Git',
        'Ability to read and understand English software requirements, strong written English, ability to conduct interviews in English',
      ],
      niceToHave: [
        'Microservice expertise',
        'Azure/AWS/Google Cloud or any equivalent cloud experience',
      ],
      typicalDay: [
        'Work with our development teams to implement features',
        'Be responsible for overall backend/api and frontend code quality',
        'Uphold our high engineering standards and bring consistency to our product development',

        'Work with our team to create both technically viable and beautiful user experiences',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Contract position',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
    fullStackEngineerDotNet: {
      role: 'Full Stack Engineer - .Net',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Mid or Senior Full-Stack Engineer to join our engineering team. This is a remote full-time contract position.`,
      idealCandidate: [
        '3+ years of experience working with .Net on a production product',
        '3+ years experience with Relational Databases',
        '2+ years of experience with React/Angular',
        'Clean Code, SOLID principles and design patterns knowledge',
        'Strong knowledge of .NET/Microsoft technologies (Asp.Net MVC/WebApi and/or WPF/UWP, Entity Framework, Windows Services etc.)',
        'Knowledge of event sourcing, message queues and caches',
        'Strong knowledge of basic software engineering concepts (data structures, algorithms, OOP)',
        'Experience writing Unit tests',
        'Strong working knowledge of Git',
        'Ability to read and understand English software requirements, strong written English, ability to conduct interviews in English',
      ],
      niceToHave: [
        'Microservice expertise',
        'Azure/AWS/Google Cloud or any equivalent cloud experience',
      ],
      typicalDay: [
        'Work with our design and development teams to implement features',
        'Be responsible for overall backend/api and frontend code quality',
        'Uphold our high engineering standards and bring consistency to our product development',
        'Work with our team to create both technically viable and beautiful user experiences',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Contract position',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `We encourage you to apply even if you don't have experience in all the above. Send us your CV and LinkedIn profile so our HRs can connect with you. We kindly ask agencies to not applying.`
    },
  },
  PRODUCT: {
    productManager: {
      role: 'Product Manager',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented Product Manager to join our professional, internationally distributed engineering and product team. This is a remote full-time position.`,
      idealCandidate: [
        '3+ years Product Management experience',
        'Strong understanding of the software product lifecycle',
        'Ability to work directly with clients and communicate effectively with stakeholders, designers, and engineers',
        'Ability to gather, understand, and document software product requirements',
        'Experience creating product requirement documentation',
        'Experience working with engineers to scope and estimate software projects',
        'Experience with App analytics, A/B Testing, and KPI analysis',
        'Ability to break down complex problems into actionable steps',
        'Proven track record of working closely with UX designers and software engineers',
        'Excellent written and verbal English skills',
        'Self-driven, with strong time management and prioritization skills',
      ],
      niceToHave: [
        '2+ Years Project Management experience',
        '1+ Years Account Management experience',
        '1+ Years Technical Writing experience',
        'Experience with user testing and conducting user interviews',
        'Experience with Javascript, HTML, CSS, SQL',
      ],
      typicalDay: [
        'Collaborate with stakeholders, product and engineering team to scope and define documentation projects',
        'Write concise documentation on our processes, flow, services and etc. that simplifies complex technical concepts into content consumable by all skill levels and roles',
        'Create product specifications on new projects',
        'Work with engineers to scope and estimate software projects',
        'Create SEO-friendly articles and blog posts',
      ],
      jobPerks: [
        'Work with a variety of clients including Fortune 500 companies',
        'Contract position',
        'Full-time remote position',
        'Flexible working hours',
      ],
      applicationInfo: `Along with your CV, include links or mentions to the relevant products you’ve lead or launched. We kindly ask agencies to not apply.`,
    }
  },
  HR: {
    hrRecruiter: {
      role: 'HR Recruiter',
      location: 'Remote',
      aboutRole: `We are currently seeking a talented HR Recruiter to join our engineering and product team. This is a remote full-time position with possibility of contract.`,
      responsibilities: [
        'Source, evaluate, interview, and hire talented team members',
        'Interact with potential candidates on professional networks (e.g. Github, Behance, etc.) and social media (e.g. LinkedIn, Facebook, Twitter, etc.)',
        'Coordinate with Hiring Managers/ HR department to determine position requirements',
        'Craft and send recruitment emails/requests',
        'Review online portfolios and resumes to pre-screen candidates',
        'Asking for referrals from external networks for hard-to-fill roles',
        'Contact past applicants for new job opportunities',
        'Organize online interviews for HR Team using all possible resources',
        'Add to talent pool for cold and hot positions and keep in touch with best TP candidates regularly',
        'Set up candidate interviews with Hiring Managers and send code challenges',
        'Measure and record conversion rates',
        'Search and find the best Middle - Senior level specialists for various IT positions',
      ],
      idealCandidate: [
        '2+ years experience in IT Recruiting with a specific focus on Software Engineering',
        'Comfortable using recruiting systems and collaboration tools (e.g. FreshTeam, Slack, etc.) to work seamlessly within a high performing HR team',
        'Have a basic understanding of modern software development tools (e.g. Github, etc.)',
        'Have established recruiting networks and understand recruiting best practices',
        'Familiar with social media marketing and digital networking',
        'Proficient in relevant office software and database systems',
        'Understands target groups and chats in instant messengers and knows how to use them in sourcing',
        'Can find and attract candidates with Boolean search, LinkedIn, and other alternative search methods',
        'Excellent communication and time management skills',
        'Good negotiation skills',
      ],
      jobPerks: [
        'Competitive salary',
        'Full-time remote position',
        '23 days of holiday',
        'Flexible working hours',
      ],
      applicationInfo: `We kindly ask agencies to not apply.`,
    }
  }
}

export default careers;

